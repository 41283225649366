import Main from '@/layout/main.vue'
// import Blank from '@/layout/blank.vue'
const routes = [
  {
    path: '/finance',
    name: 'finance',
    meta: { title: '财务管理' },
    redirect: '/finance/region_agent',
    component: Main,
    children: [
      {
        path: '/finance/region_agent',
        name: 'finance_region_agent',
        meta: {
          title: '区域代理',
          icon: 'icon_user_guanli',
          parentPath: '/finance',
          permission: ['view']
        },
        component: () => import('@/views/finance/region_agent/lists.vue')
      },
      {
        path: '/finance/region_agent_add',
        name: 'finance_region_agent_add',
        meta: {
          hidden: true,
          title: '添加区域代理',
          parentPath: '/finance',
          prevPath: '/finance/region_agent',
          permission: ['view']
        },
        component: () => import('@/views/finance/region_agent/add.vue')
      },
      {
        path: '/finance/region_agent_edit',
        name: 'finance_region_agent_edit',
        meta: {
          hidden: true,
          title: '编辑区域代理',
          parentPath: '/finance',
          prevPath: '/finance/region_agent',
          permission: ['view']
        },
        component: () => import('@/views/finance/region_agent/edit.vue')
      },
      {
        path: '/finance/region_agent_withdraw',
        name: 'finance_region_agent_withdraw',
        meta: {
          hidden: true,
          title: '区域代理提现',
          parentPath: '/finance',
          prevPath: '/finance/region_agent',
          permission: ['view']
        },
        component: () => import('@/views/finance/region_agent/withdraw.vue')
      }
    ]
  }
]
export default routes
