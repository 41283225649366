import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
// import Blank from '@/layout/blank.vue'
const routes = [
  {
    path: '/admin',
    name: 'admin',
    meta: { title: '商城管理' },
    redirect: '/admin/user/lists',
    component: Main,
    children: [
      {
        path: '/admin/user/lists',
        name: 'admin_user_lists',
        meta: {
          title: '商城用户',
          icon: 'icon_user_guanli',
          parentPath: '/admin',
          permission: ['view']
        },
        component: () => import('@/views/admin/user/lists.vue')
      },
      {
        path: '/admin/user/equity_list',
        name: 'package_record',
        meta: {
          hidden: true,
          title: '套餐记录',
          parentPath: '/admin',
          prevPath: '/admin/user/lists',
          permission: ['view']
        },
        component: () => import('@/views/admin/user/equity_list.vue')
      },
      {
        path: '/admin/order/lists',
        name: 'lists',
        meta: {
          title: '商城订单',
          parentPath: '/admin',
          icon: 'icon_order_guanli',
          permission: ['view']
        },
        component: Blank,
        redirect: '/admin/order/lists',
        children: [
          {
            path: '/admin/order/lists',
            name: 'admin_order_lists',
            meta: {
              title: '订单列表',
              parentPath: '/admin',
              permission: ['view']
            },
            component: () => import('@/views/admin/order/lists.vue')
          },
          {
            path: '/admin/order/after_sales',
            name: 'admin',
            meta: {
              title: '售后订单',
              parentPath: '/admin',
              permission: ['view']
            },
            component: () => import('@/views/admin/order/after_sales.vue')
          }
        ]
      }
    ]
  }
]
export default routes
